/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Div, ModuleContainer, Text } from "../../styles/Common";
import Tabs from "../../components/Tabs";
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import useGetStrategies from "./hooks/useGetStrategies";
import { SaleTable } from "./components/saleTable";
import editIcon from "../../assets/icons/Edit-green.svg";
import deleteIcon from "../../assets/icons/delete-green.svg";
import { FinancingTable } from "./components/financingTable";
import { DrawerNewStrategy } from "./components/drawerNewStrategy";
import {
  AssetsOptions,
  CountriesOptions,
  VerticalOptions,
} from "../../utilities/strategiesOptions";
import useCreateStrategies from "./hooks/useCreateStrategy";
import { Link } from "../../components/Link";
import { ModalSourceType } from "./components/modalSourceType";
import { Modal } from "../../components/Modal";
import { ArrowDownIcon, ArrowLeftIcon, LikeIcon } from "../../assets/icons";
import {
  assetTypeOptions,
  countriesOptions as countriesLabel,
} from "../../utilities/generalOptions";

export const Strategies = () => {
  const { t } = useTranslation("strategies");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");
  const [payload, setPayload] = useState({
    limit: 10,
    offset: 0,
    type: 2,
  });
  const getStrategies = useGetStrategies();
  const create = useCreateStrategies();
  const [activeKey, setActiveKey] = useState("1");
  const [pages, setPages] = useState({ pageNumber: 1, pageSize: 10 });
  const [totalItems, setTotalItems] = useState(10);
  const [strategiesList, setStrategiesList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const initForm = {
    name: "",
    typeOfInvestment: null,
    investmentInstrument: null,
    description: "",
    vertical: null,
    assets: [],
    countries: [],
    type: 2,
  };
  const [strategiesForm, setStrategiesForm] = useState(initForm);
  const [assetsOptions, setAssetsOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [openModalSource, setOpenModalSource] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);

  const handleExpand = (record) => {
    setExpandedRowKey(expandedRowKey === record.key ? null : record.key);
  };

  useEffect(() => {
    if (key) {
      const type = key === "1" ? 2 : 1;
      setActiveKey(key);
      setPayload({ ...payload, type: type });
      setStrategiesForm({ ...strategiesForm, type: type });
    }
  }, [key]);

  useEffect(() => {
    setAssetsOptions(
      AssetsOptions.map((ele, indx) => ({
        ...ele,
        key: indx,
        checked: false,
      }))
    );

    setCountriesOptions(
      CountriesOptions.map((ele, indx) => ({
        ...ele,
        key: indx,
        checked: false,
      }))
    );
  }, []);

  const fetchList = () => {
    getStrategies.reset();
    getStrategies.mutate(
      { ...payload },
      {
        onSuccess: (res) => {
          if (res?.data.result.data.data.length > 0) {
            const dataTable = res?.data.result.data.data.map((ele, i) => ({
              ...ele,
              key: `${ele.id}`,
              actions: [
                {
                  key: `1${i}`,
                  id: ele.id,
                  icon: editIcon,
                },
                {
                  key: `1${i}`,
                  id: ele.id,
                  icon: deleteIcon,
                },
              ],
              sources: {
                id: ele.id,
                icon: editIcon,
                financialSource: ele.financialSource,
              },
            }));

            setStrategiesList(dataTable);
            setTotalItems(res?.data.result.data.data.length);
          } else {
            setStrategiesList([]);
          }
        },
      }
    );
  };

  useEffect(() => {
    fetchList();
  }, [payload]);

  const onChange = (key) => {
    const type = key === "1" ? 2 : 1;
    setActiveKey(key);
    setPayload({ ...payload, type: type });
    setStrategiesForm({ ...strategiesForm, type: type });
  };

  const sourceText = (length) => {
    switch (length) {
      case 0:
        return t("noSources");
      case 1:
        return `${length} ${t("source")}`;
      default:
        return `${length} ${t("sources")}`;
    }
  };

  const tableColumns = [
    {
      title: t("strategyName"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("vertical"),
      dataIndex: "vertical",
      key: "vertical",
      render: (row) => {
        return VerticalOptions[row - 1]?.label;
      },
    },
    {
      title: t("assets"),
      dataIndex: "assets",
      key: "assets",
      render: (row) => {
        const arrayStrings = row.map((ele) => assetTypeOptions[ele - 1].label);
        return arrayStrings && arrayStrings.join();
      },
    },
    {
      title: t("countries"),
      dataIndex: "countries",
      key: "countries",
      render: (row) => {
        const arrayStrings = row.map((ele) => countriesLabel[ele - 1].label);
        return arrayStrings && arrayStrings.join();
      },
    },
  ];

  if (activeKey === "1") {
    tableColumns.push({
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      align: "left",
      render: (actions) => (
        <Div gap="20px">
          {actions.map((action, i) => (
            <img
              key={i}
              alt="icon"
              src={action.icon}
              style={{ cursor: "pointer" }}
            />
          ))}
        </Div>
      ),
    });
  } else if (activeKey === "2") {
    tableColumns.push({
      title: t("sources"),
      dataIndex: "sources",
      key: "sources",
      render: (source, record) => (
        <Div gap="6px" align="center">
          <Div align="center" gap="3px" minwidth="120px">
            <Link weight={theme.fonts.weight.regular} style={{ width: "80%" }}>
              {sourceText(source.financialSource.length)}
            </Link>
            <Div width="20%" onClick={() => handleExpand(record)}>
              {expandedRowKey === record.key ? (
                <Link
                  background={"transparent"}
                  icon={
                    <ArrowDownIcon width={"24px"} fill={theme.colors.green} />
                  }
                />
              ) : (
                <Link
                  background={"transparent"}
                  icon={
                    <ArrowLeftIcon
                      width={"24px"}
                      fill={
                        source.financialSource.length === 0
                          ? theme.colors.gray100
                          : theme.colors.green
                      }
                    />
                  }
                  disabled={source.financialSource.length === 0}
                />
              )}
            </Div>
          </Div>

          <img
            key={source.id}
            alt="icon"
            src={source.icon}
            style={{ cursor: "pointer" }}
          />
        </Div>
      ),
    });
  }

  const handleChangeTable = (pagination) => {
    setPages({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const panels = [
    {
      key: "1",
      tab: t("sale"),
      type: 2,
      children: (
        <SaleTable
          tableColumns={tableColumns}
          data={strategiesList}
          pages={pages}
          totalItems={totalItems}
          handleChangeTable={handleChangeTable}
          isLoading={getStrategies.isLoading}
        />
      ),
    },
    {
      key: "2",
      tab: t("financing"),
      type: 1,
      children: (
        <FinancingTable
          tableColumns={tableColumns}
          data={strategiesList}
          pages={pages}
          totalItems={totalItems}
          handleChangeTable={handleChangeTable}
          isLoading={getStrategies.isLoading}
          expandedRowKey={expandedRowKey}
          fetchList={fetchList}
        />
      ),
    },
  ];

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setStrategiesForm(initForm);
    setAssetsOptions(
      AssetsOptions.map((ele, indx) => ({
        ...ele,
        key: indx,
        checked: false,
      }))
    );
    setCountriesOptions(
      CountriesOptions.map((ele, indx) => ({
        ...ele,
        key: indx,
        checked: false,
      }))
    );
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    const newStrategyForm = { ...strategiesForm };

    newStrategyForm[id] = value;
    setStrategiesForm(newStrategyForm);
  };

  const handleSelect = (value, id) => {
    const newStrategyForm = { ...strategiesForm };

    newStrategyForm[id] = value;
    setStrategiesForm(newStrategyForm);
  };

  const handleRadio = (event) => {
    const { name, value } = event.target;
    const newStrategyForm = { ...strategiesForm };

    newStrategyForm[name] = value;
    setStrategiesForm(newStrategyForm);
  };

  const handleCheckAssets = (event, index) => {
    const { checked, id } = event.target;
    const newStrategyForm = { ...strategiesForm };
    const newAssetsOptions = [...assetsOptions];

    newStrategyForm.assets = [...newStrategyForm.assets, id];
    newAssetsOptions[index].checked = checked;

    setStrategiesForm(newStrategyForm);
    setAssetsOptions(newAssetsOptions);
  };

  const handleCheckCountries = (event, index) => {
    const { checked, id } = event.target;
    const newStrategyForm = { ...strategiesForm };
    const newCountriesOptions = [...countriesOptions];

    newStrategyForm.countries = [...newStrategyForm.countries, id];
    newCountriesOptions[index].checked = checked;

    setStrategiesForm(newStrategyForm);
    setCountriesOptions(newCountriesOptions);
  };

  const handleCreate = () => {
    create.reset();
    create.mutate(strategiesForm, {
      onSuccess: () => {
        setShowModalSuccess(true);
        handleClose();
        fetchList();
      },
    });
  };

  const handleOpenModal = () => {
    setOpenModalSource(true);
  };

  const handleCloseModal = () => {
    setOpenModalSource(false);
  };

  const handleCreateSource = (label) => {
    navigate(`/strategies/source?type=${label}`);
  };

  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
  };

  return (
    <ModuleContainer padding="35px 69px 0 32px" direction="column">
      <Modal open={showModalSuccess} onCancel={handleCloseModalSuccess}>
        <Modal.Header
          icon={
            <LikeIcon
              stroke={theme.colors.green}
              width={"50px"}
              height={"50px"}
            />
          }
          iconBackground={theme.colors.green100}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"20px 63px 0px 63px"}
          title={t("strategySuccessTitle")}
          weightTitle={theme.fonts.weight.medium}
        />
        <Modal.Body margin="28px 0 32px 0">
          <Text
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
            style={{ textAlign: "center" }}
          >
            {t("strategySuccessMessage")}
          </Text>
        </Modal.Body>
      </Modal>
      <ModalSourceType
        open={openModalSource}
        handleClose={handleCloseModal}
        handleCreate={handleCreateSource}
      />
      <DrawerNewStrategy
        open={openDrawer}
        handleClose={handleClose}
        form={strategiesForm}
        assetsOptions={assetsOptions}
        countriesOptions={countriesOptions}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleRadio={handleRadio}
        handleCheckAssets={handleCheckAssets}
        handleCheckCountries={handleCheckCountries}
        handleCreate={handleCreate}
        type={strategiesForm.type}
      />
      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onChange}>
        {panels.map((panel) => (
          <Tabs.Panel tab={panel.tab} key={panel.key}>
            <Div
              width="100%"
              align="center"
              justify="space-between"
              m="34px 0 32px 0"
            >
              <Text
                color={theme.colors.gray500}
                size={theme.fonts.size.h5}
                weight={theme.fonts.weight.semibold}
              >
                {activeKey === "1"
                  ? t("saleStrategies")
                  : t("financingStrategies")}
              </Text>
              <Div align="center" gap="16px">
                {activeKey === "2" && (
                  <Button variant={"outlined"} onClick={handleOpenModal}>
                    {t("newSource")}
                  </Button>
                )}
                <Button onClick={handleOpen}>{t("newStrategy")}</Button>
              </Div>
            </Div>
            {panel.children}
          </Tabs.Panel>
        ))}
      </Tabs>
    </ModuleContainer>
  );
};
