import React, { useState, useEffect } from "react";
//import PropTypes from "prop-types";
import { Div, Text, Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import search from "../../../assets/icons/Search.svg";
import { Table } from "../../../components/Table";
import { Skeleton } from "../../../components/Skeleton";
import { dateFormat } from "../../../utilities/helpers";
import { DrawerNewInvestmentGroup } from "./DrawerNewInvestmentGroup";
import ModalSuccess from "../../NewProject/components/ModalSuccess";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import useGetAllUsers from "../hooks/useGetAllUsers";
import useDebounce from "../../../core/hooks/useDebounce";
import {
  StrategyAutoIcon,
  ListIcon,
  DeleteIcon,
  HistoryIcon,
} from "../../../assets/icons";
import { DrawerStrategies } from "../../InviteInvestor/components/DrawerStrategies";
export const Investor = () => {
  const { t } = useTranslation("users");
  const navigate = useNavigate();
  const [pages, setPages] = useState({ pageNumber: 0, pageSize: 10 });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerStrategies, setOpenDrawerStrategies] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useDebounce(searchTerm, 600);
  const [investorList, setInvestorList] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const initialDrawerValues = {
    nameCompany: "",
    address: "",
    logo: null,
    document: null,
  };
  const [form, setForm] = useState(initialDrawerValues);

  const initialErrorForm = {
    nameCompany: { error: false, message: "" },
    address: { error: false, message: "" },
    logo: { error: false, message: "" },
    document: { error: false, message: "" },
  };
  const [errorForm, setErrorForm] = useState(initialErrorForm);

  const [payloadOptions, setPayloadOptions] = useState({
    limit: pages.pageSize,
    offset: pages.pageNumber,
    name: searchValue,
    userRole: "Investor",
  });

  useEffect(() => {
    setPayloadOptions({
      limit: pages.pageSize,
      offset: pages.pageNumber,
      name: searchValue,
      userRole: "Investor",
    });
  }, [pages, searchValue]);

  const getAllUsers = useGetAllUsers(payloadOptions);
  const { data, isLoading } = getAllUsers;

  useEffect(() => {
    setTotalItems(data?.data?.total);
    setInvestorList(
      data?.data?.data.map((ele, indx) => ({
        key: indx,
        createdAt: ele?.createdAt,
        companyName:
          ele?.companyInfo?.name.charAt(0).toUpperCase() +
            ele?.companyInfo?.name.slice(1) || "-",
        name: ele?.name,
        strategy: ele?.companyInfo?.strategies.join(", ") || "-",
        terms: ele?.companyInfo?.termsAndConditionsUrl ? "Si" : "Reenviar",
        actions: [
          {
            id: ele?._id,
            name: ele?.name,
            tooltip: t("editStrategies"),
            icon: (
              <StrategyAutoIcon
                width="32px"
                height="32px"
                fill={theme.colors.green}
                onClick={() => {
                  setOpenDrawerStrategies(true);
                  setSelectedCompany(ele?.companyId);
                }}
              />
            ),
          },
          {
            id: ele._id,
            name: ele.name,
            tooltip: t("investorActivity"),
            icon: (
              <ListIcon
                width="32px"
                height="32px"
                fill={theme.colors.green}
                onClick={() => handleInvestorActivity(ele._id)}
              />
            ),
          },
          {
            id: ele._id,
            name: ele.name,
            tooltip: t("deleteInvestor"),
            icon: (
              <DeleteIcon
                width="32px"
                height="32px"
                fill={theme.colors.green}
                onClick={() => {}}
              />
            ),
          },
        ],
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleInvestorActivity = (id) => {
    navigate(`/users/investor-activity/${id}`);
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const handleChangeTable = (pagination) => {
    const newPayload = { ...payloadOptions };
    newPayload.offset = pagination.current - 1;
    setPayloadOptions(newPayload);
    setPages({
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setForm(initialDrawerValues);
    setErrorForm(initialErrorForm);
    setOpenDrawerStrategies(false);
    setSelectedCompany(null);
  };

  const handleCloseShowModal = () => {
    setShowModal(false);
    navigate("invite-investor");
  };

  const tableColumns = [
    {
      title: t("date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => dateFormat(text),
    },
    {
      title: t("investorComponent.companyName"),
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: t("investorComponent.fullName"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("investorComponent.strategies"),
      dataIndex: "strategy",
      key: "strategy",
      render: (value) => <Text align="center">{value}</Text>,
    },
    {
      title: t("investorComponent.t&c"),
      dataIndex: "terms",
      key: "terms",
      render: (value) => (
        <Row align="center" justify="center">
          {value === "Reenviar" && <HistoryIcon fill={theme.colors.green} />}
          <Text
            color={
              value === "Reenviar" ? theme.colors.green : theme.colors.gray500
            }
          >
            {value}
          </Text>
        </Row>
      ),
    },
    {
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      render: (actions) => (
        <Div justify="space-between" style={{ cursor: "pointer" }}>
          {actions.map((action, i) => (
            <Tooltip
              key={i}
              title={action.tooltip}
              color={theme.colors.white}
              placement="topRight"
              overlayInnerStyle={{
                color: theme.colors.gray300,
                fontFamily: theme.fonts.family,
                fontSize: theme.fonts.size.sm,
              }}
            >
              {action.icon}
            </Tooltip>
          ))}
        </Div>
      ),
    },
  ];

  const handleInviteInvestor = () => {
    navigate("invite-investor");
  };

  return (
    <Div direction="column">
      <ModalSuccess
        showModal={showModal}
        handleCloseModal={handleCloseShowModal}
        page={"users"}
      />
      <DrawerNewInvestmentGroup
        openDrawer={openDrawer}
        handleClose={handleCloseDrawer}
        setShowModal={setShowModal}
        form={form}
        setForm={setForm}
        errorForm={errorForm}
        setErrorForm={setErrorForm}
      />
      <DrawerStrategies
        openDrawer={openDrawerStrategies}
        handleClose={handleCloseDrawer}
        selectedCompany={selectedCompany}
      />
      <Div
        width="100%"
        align="center"
        justify="space-between"
        m="34px 0 32px 0"
      >
        <Text
          color={theme.colors.gray500}
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.semibold}
        >
          {t("investor")}
        </Text>
        <Div align="center" gap="16px">
          <Input
            sizeLabel={theme.fonts.size.xs}
            suffix={
              <img alt="search" src={search} style={{ cursor: "pointer" }} />
            }
            width={"332px"}
            placeholder={`${t("search")}...`}
            style={{ borderRadius: "23px", marginTop: "-10px" }}
            mt={"-8px"}
            onChange={handleChangeSearch}
            value={searchTerm}
          />
          <Button
            width={"209px"}
            variant={"outlined"}
            onClick={() => setOpenDrawer(true)}
          >
            {t("investorComponent.investmentGroup")}
          </Button>
          <Button width={"169px"} onClick={handleInviteInvestor}>
            {t("investorComponent.inviteInversor")}
          </Button>
        </Div>
      </Div>
      <Table
        columns={tableColumns}
        data={investorList}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={{
          defaultCurrent: 1,
          ...pages,
          total: totalItems,
          position: ["bottomRight"],
          showSizeChanger: false,
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Div justify="center" gap="16px">
              {[...Array(9)].map((item, idx) => (
                <Skeleton
                  key={idx}
                  title={false}
                  paragraph={{
                    rows: 10,
                    width: "100%",
                  }}
                  loading
                />
              ))}
            </Div>
          ) : (
            "No Data"
          ),
        }}
      />
    </Div>
  );
};

Investor.propTypes = {};
