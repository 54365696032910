import React from "react";
import PropTypes from "prop-types";
import { Text } from "../../../styles/Common";
import { Row } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { DownloadIcon } from "../../../assets/icons";
import { Card } from "../../../components/Card";
import { useTranslation } from "react-i18next";
import { dateFormat, downloadAndOpenFile } from "../../../utilities/helpers";
import useGetDocuments from "../../Documents/hooks/useGetDocuments";
import { downloadFile, getToken } from "../../../utilities/helpers";
import { axiosClient } from "../../../core/services/axiosInstance";
import FinancialDashboardDetailProject from "./DebtProject/FinancialDashboardDetailProject";

export const SummaryProject = ({
  projectInfo,
  debt,
  termsApproved,
  setShowModal,
}) => {
  const { t } = useTranslation("investorProjectDetail");

  const { data: dataPromoterDocument } = useGetDocuments(projectInfo?.id, 2, 1);

  const titleTable = [
    { title: t("name") },
    { title: "P (MW)" },
    { title: t("hours") },
    { title: t("dateRtB") },
    { title: t("dateCOD") },
    { title: "P -50" },
    { title: "P -90" },
    { title: t("authentication") },
  ];

  const handleDownload = () => {
    if (!termsApproved) {
      setShowModal(true);
    } else {
      downloadFile(
        `documentation/download/${projectInfo?.id}/1`,
        "Promoter presentation",
        axiosClient,
        getToken()
      );
    }
  };

  const handleDownloadTeaser = () => {
    downloadAndOpenFile(
      `teaser/${projectInfo?.teaserId?._id}/pdf?lang=${projectInfo.teaserId?.projectSummary[0]?.language}`,
      "Teaser",
      axiosClient,
      getToken()
    );
  };

  return (
    <>
      <Row justify="space-between" align="center" m=" 0 0 23px 0">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h5i}
          color={theme.colors.blue}
        >
          {" "}
          {t("summary")}{" "}
        </Text>
        {projectInfo?.teaserId?.isPublish && (
          <Button
            width="186px"
            height="45px"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
            onClick={handleDownloadTeaser}
          >
            <DownloadIcon fill={theme.colors.white} />
            {t("download")}
          </Button>
        )}
      </Row>
      <Card
        margin="0 0 24px 0"
        width="100%"
        height="auto"
        padding="23px 35px 34px 26px"
      >
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h5i}
          color={theme.colors.blue}
          mb="41px"
        >
          {t("plantInformation")}
        </Text>
        <Row justify="space-between" height="54px">
          {titleTable.map((title, index) => (
            <Text
              key={index}
              width="164px"
              color={theme.colors.green}
              minheight="53px"
              style={{ width: index === 0 && "148px" }}
            >
              {title.title}
            </Text>
          ))}
        </Row>
        {projectInfo?.plants.map((plant, index) => (
          <Row
            key={index}
            justify="space-between"
            height="54px"
            align="center"
            style={{
              background: index % 2 !== 0 && theme.colors.green100,
              borderRadius: "8px",
            }}
          >
            <Text
              style={{ width: "160px" }}
              color={theme.colors.gray500}
              minheight="53px"
            >
              {plant?.name}
            </Text>
            <Text
              style={{ width: "90px" }}
              color={theme.colors.gray500}
              minheight="53px"
            >
              {plant?.potenciaPico}
            </Text>
            <Text
              style={{ width: "119px" }}
              color={theme.colors.gray500}
              minheight="53px"
            >
              {plant?.horasEquivalentes || plant?.horasEquivalentes90}
            </Text>
            <Text
              style={{ width: "158px" }}
              color={theme.colors.gray500}
              minheight="53px"
            >
              {plant?.fechaEstimadaRtBPlanta || plant?.fechaPuestaEnMarcha
                ? dateFormat(
                    plant?.fechaEstimadaRtBPlanta || plant?.fechaPuestaEnMarcha
                  )
                : "--"}
            </Text>
            <Text
              style={{ width: "164px" }}
              color={theme.colors.gray500}
              minheight="53px"
            >
              {plant?.fechaEstimadaCoDPlanta
                ? dateFormat(plant?.fechaEstimadaCoDPlanta)
                : "--"}
            </Text>
            <Text
              style={{ width: "81px" }}
              color={theme.colors.gray500}
              minheight="53px"
            >
              {plant?.horasEquivalentes || "--"}
            </Text>
            <Text
              style={{ width: "81px" }}
              color={theme.colors.gray500}
              minheight="53px"
            >
              {plant?.horasEquivalentes90 || "--"}
            </Text>
            <Text
              style={{ width: "164px" }}
              color={theme.colors.gray500}
              minheight="53px"
            >
              {plant?.ultimaAutenticacion || "--"}
            </Text>
          </Row>
        ))}
      </Card>
      <Card
        width="100%"
        height="auto"
        padding="23px 27px 36px 24px"
        margin="0 0 50px 0"
      >
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h5i}
          color={theme.colors.blue}
          mb="16px"
        >
          {t("promoterDescription")}
        </Text>
        <Text color={theme.colors.gray300} mb="24px">
          {projectInfo?.sponsor?.sponsorDescription || "--"}
        </Text>
        {dataPromoterDocument?.data?.data.length > 0 && (
          <Button
            width="251px"
            height="45px"
            variant={"outlined"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
            onClick={handleDownload}
          >
            <DownloadIcon fill={theme.colors.green} />
            {t("presentation")}
          </Button>
        )}
      </Card>
      {debt && <FinancialDashboardDetailProject />}
    </>
  );
};

SummaryProject.propTypes = {
  projectInfo: PropTypes.object,
  debt: PropTypes.bool,
  termsApproved: PropTypes.bool,
  setShowModal: PropTypes.func,
};
