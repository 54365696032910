import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ModuleContainer, Row, Text, Col } from "../../styles/Common";
import { useQueryClient } from "react-query";
import { theme } from "../../styles/theme";
import { Card } from "../../components/Card";
import { Collapse } from "../../components/Collapse";
import { Select } from "../../components/Select2";
import { Radio } from "../../components/Radio";
import { Button } from "../../components/Button";
import { ProjectSummary } from "./components/projectSummary";
import { Milestones } from "./components/milestones";
import { SponsorDescription } from "./components/sponsorDescription";
import { StateProject } from "./components/stateProject";
import { EyeIcon } from "../../assets/icons";
import Tabs from "../../components/Tabs";
import { useTranslation } from "react-i18next";
import useGetTeaser from "./hooks/useGetTeaser";
import useCreateTeaserSale from "./hooks/useCreateTeaserSale";
import useCreateTeaserFinancing from "./hooks/useCreateTeaserFinancing";
import useUpdateTeaser from "./hooks/useUpdateTeaser";
import { FinancingStrategy } from "./components/FinancingStrategy";
import { Strategy } from "./components/Strategy";
import dayjs from "dayjs";
import useGetStrategyByFinancingSource from "./hooks/useGetStrategyByFinancingSource";
import useGetStrategyByType from "./hooks/useGetStrategyByType";
import { axiosClient } from "../../core/services/axiosInstance";
import { getToken, downloadAndOpenFile } from "../../utilities/helpers";

export const TeaserDocumentation = () => {
  const { t, i18n } = useTranslation("teaserDocumentation");
  const lang = i18n.language;
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const teaserId =
    new URLSearchParams(search).get("teaserId") === "undefined"
      ? null
      : new URLSearchParams(search).get("teaserId");
  const projectId = new URLSearchParams(search).get("projectId");
  const projectName = new URLSearchParams(search).get("projectName");
  const strategyId = new URLSearchParams(search).get("strategyId");

  const saveTeaserSale = useCreateTeaserSale();
  const saveTeaserFinancing = useCreateTeaserFinancing();
  const editTeaser = useUpdateTeaser();

  const [activeKey, setActiveKey] = useState(["0"]);
  const [tabActiveKey, setTabActiveKey] = useState("1");
  const [strategiesTabs, setStrategiesTabs] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const getStrategyByFinancingSource =
    useGetStrategyByFinancingSource(projectId);
  const { data: dataInitialTeaser } = getStrategyByFinancingSource;
  const [projectType, setProjectType] = useState({});
  useEffect(() => {
    if (dataInitialTeaser?.data?.data) {
      const { assets } =
        dataInitialTeaser?.data?.data.financingSource?.strategyId || {};
      setProjectType(dataInitialTeaser?.data?.data?.projectType.instance);
      if (teaserId === null) {
        setProjectSummary((prevState) => ({
          ...prevState,
          projectName: dataInitialTeaser?.data?.data.name,
          constructionDeclared: dataInitialTeaser?.data?.data.capex,
          projectSize: dataInitialTeaser?.data?.data.projectTotalMW,
          numberFloors: dataInitialTeaser?.data?.data.numberFloors,
          assetType:
            projectType.order === 1
              ? assets
              : [dataInitialTeaser?.data?.data?.projectType?.type?.name],
          assetVertical:
            projectType.order === 1
              ? [
                  dataInitialTeaser?.data?.data?.financingSource?.strategyId
                    ?.vertical,
                ]
              : [dataInitialTeaser?.data?.data?.projectType?.type?.name],
          projectProfile: dataInitialTeaser?.data?.data?.agreementType,
          ppa: dataInitialTeaser?.data?.data
            ?.agreementPPAElectricitySoldPercentage,
          teaserLang: dataInitialTeaser?.data?.data?.language || lang,
        }));

        const countriesNames =
        dataInitialTeaser?.data?.data?.companyData?.countries?.map(
          (ele) => ele.name
        ) ?? [];
        setSponsorDescription((prevState) => ({
          ...prevState,
          description: dataInitialTeaser?.data?.data.sponsor.sponsorDescription,
          yearsExperience:
            dataInitialTeaser?.data?.data?.companyData?.experiencesYears,
          completedProjects:
            dataInitialTeaser?.data?.data?.companyData?.completedProjects,
          totalMw: dataInitialTeaser?.data?.data?.companyData?.totalMW,
          presenceCountries: countriesNames,
        }));
        setStateOfProject((stateOfProject) => ({
          ...stateOfProject,
          plantsUnderConstructionRTB:
            dataInitialTeaser?.data?.data.plantsWithRtBDate,
          RTBForFirstPlantExpected: dataInitialTeaser?.data?.data.newestRtBDate
            ? dayjs(dataInitialTeaser?.data?.data.oldestRtBDate)
            : null,
          RTBForLastPlantExpected: dataInitialTeaser?.data?.data.oldestRtBDate
            ? dayjs(dataInitialTeaser?.data?.data.newestRtBDate)
            : null,
          plantsCurrentlyCOD: dataInitialTeaser?.data?.data?.plantsWithCOD,
          CODOfFirstPlantExpected: dataInitialTeaser?.data?.data.newestCODDate
            ? dayjs(dataInitialTeaser?.data?.data.oldestCODDate)
            : null,
          CODOfLastPlantExpected: dataInitialTeaser?.data?.data.oldestCODDate
            ? dayjs(dataInitialTeaser?.data?.data.newestCODDate)
            : null,
        }));
      }
    }
  }, [dataInitialTeaser, teaserId, lang, projectType]);

  const getStrategyByType = useGetStrategyByType(projectType?.order);
  const { data: dataStrategyType, isSuccess: isSuccessStrategyType } =
    getStrategyByType;
  const [strategiesList, setStrategiesList] = useState([]);

  useEffect(() => {
    if (dataStrategyType?.data?.data && isSuccessStrategyType) {
      const filteredStrategies = dataStrategyType.data.data
        .filter(
          (strategy) =>
            !(projectType.order === 1 && strategy.investmentInstrument === null)
        )
        .map((strategy) => ({
          value: strategy.name,
          label: strategy.name,
          type: strategy.type,
          investmentInstrument: Number(strategy.investmentInstrument),
          id: strategy._id,
        }));

      setStrategiesList(filteredStrategies);
    }
  }, [dataStrategyType, isSuccessStrategyType, projectType]);

  const teaserDataInfo = useGetTeaser(teaserId);
  const { data: dataTeaser, isSuccess: isSuccessDataTeaser } = teaserDataInfo;
  const [projectSummary, setProjectSummary] = useState({
    document: null,
    projectName: "",
    assetType: null,
    assetVertical: null,
    projectSize: null,
    projectStatus: null,
    country: null,
    community: null,
    currency: null,
    constructionDeclared: null,
    numberFloors: null,
    projectProfile: null,
    ppa: null,
    otherInformation: null,
    teaserLang: null,
  });
  const [keyMilestones, setKeyMilestones] = useState([
    {
      name: "",
      date: null,
    },
  ]);
  const [sponsorDescription, setSponsorDescription] = useState({
    description: "",
    yearsExperience: null,
    completedProjects: null,
    totalMw: null,
    presenceCountries: [],
  });
  const [stateOfProject, setStateOfProject] = useState({
    plantsUnderConstructionRTB: null,
    RTBForFirstPlantExpected: null,
    RTBForLastPlantExpected: null,
    plantsCurrentlyCOD: null,
    CODOfFirstPlantExpected: null,
    CODOfLastPlantExpected: null,
  });
  const strategySaleStructure = {
    transactionSummary: "",
    developmentRisk: "",
    paymentMilestones: "",
    minimumPaymentAtClosing: null,
    strategy: "",
    acquisitionOfMajorityStake: null,
    sellerToBeRetainedAsEPCContractor: null,
    targetPricePerMWpChecked: null,
    targetPricePerMWpValue: null,
    pricePerMWpChecked: null,
    pricePerMWpValue: null,
    negotiablePrice: null,
    pricePerMWpTo: null,
    priceConsiderations: "",
    feeType: null,
    feeConsiderations: "",
    dateClosing: null,
    includeTeaserProposal: null,
    hideToSponsor: null,
    hideAnnexed: null,
    other: "",
  };
  const [strategiesSale, setStrategiesSale] = useState([]);

  const strategyFinancingStructure = {
    transactionSummary: {
      financingType: "",
      loan: {
        targetAmount: null,
        tenor: null,
        margin: null,
        marginVariable: null,
        nonCall: null,
        debtProfile: null,
      },
      esiBond: {
        details: "",
        seniorSecuredProjectBond: null,
        issuer: "",
        amount: null,
        coupon: null,
        profile: "",
        maturity: null,
        security: "",
        listing: "",
        maxLoanLeverageOverCapexFrom: null,
        maxLoanLeverageOverCapexTo: null,
      },
    },
    platformFee: {
      openingFee: null,
      runningFee: null,
      alter5PlatformFeeText: "",
    },
    anexoOne: {
      underlyingDebtTenor: "",
      p90: null,
      p50: null,
      annualDegradation: null,
      averageDscr: "",
      priceCurve: null,
      yearsRangeFrom: null,
      yearsRangeTo: null,
      inflationPercent: null,
      startFromYear: null,
      operationCostAlter5PlatformFeeText: "",
    },
    anexoTwo: {
      epc: null,
      otherCapex: null,
      preOperationalCosts: null,
      upfrontFee: null,
      advisors: null,
      agencyFee: null,
      financialExpenses: null,
      dsraEndowment: null,
      equity: null,
      seniorDebt: null,
    },
    anexoThree: {
      debtToCapitalRatio: null,
      debtToMwRatio: null,
      underlyingLoanSensitivities: "",
    },
    anexoFour: {
      plant: "",
      lineDistanceFrom: null,
      lineDistanceTo: null,
    },
    additionalDocumentation: {
      expectedClosingDate: null,
      includeProposedCapitalStructureInTeaser: null,
      hideTeaserToSponsor: null,
      hideAnnex1: null,
      hideAnnex2: null,
      hideAnnex3: null,
      hideAnnex4: null,
    },
  };
  const [strategiesFinancing, setStrategiesFinancing] = useState([]);

  useEffect(() => {
    if (isSuccessDataTeaser && dataTeaser?.data?.data) {
      const info = dataTeaser?.data?.data;
      setProjectSummary({
        document: null,
        projectName: info.projectSummary[0].projectName,
        assetType: info.projectSummary[0].assetType,
        assetVertical: info.projectSummary[0].assetVertical,
        projectSize: info.projectSummary[0].projectSize,
        projectStatus: info.projectSummary[0].projectStatus,
        country: info.projectSummary[0].country,
        community: info.projectSummary[0].autonomousCommunity,
        currency: info.projectSummary[0].currency,
        constructionDeclared:
          info.projectSummary[0].declaredEstimatedConstruction,
        numberFloors: info.projectSummary[0].numberOfPlants,
        projectProfile: info.projectSummary[0].projectRevenuesProfile,
        ppa: info.projectSummary[0].ppas,
        otherInformation: info.projectSummary[0].otherRelevantInformation,
        teaserLang: info.projectSummary[0]?.language || lang,
      });
      setKeyMilestones(
        info.keyMilestones.map((milestone) => ({
          name: milestone.name,
          date: milestone?.date ? dayjs(milestone?.date) : null,
        }))
      );
      setSponsorDescription({
        description: info.sponsorDescription.description,
        yearsExperience: info.sponsorDescription.yearsOfExperience,
        completedProjects: info.sponsorDescription.completedProjects,
        totalMw: info.sponsorDescription.totalMWDeveloped,
        presenceCountries: info.sponsorDescription.presenceIn,
      });
      setStateOfProject({
        plantsUnderConstructionRTB: Number(
          info.stateOfProject.plantsUnderConstructionRTB
        ),
        RTBForFirstPlantExpected: info?.stateOfProject.RTBForFirstPlantExpected
          ? dayjs(info?.stateOfProject.RTBForFirstPlantExpected)
          : null,
        RTBForLastPlantExpected: info?.stateOfProject.RTBForLastPlantExpected
          ? dayjs(info?.stateOfProject.RTBForLastPlantExpected)
          : null,
        plantsCurrentlyCOD: Number(info.stateOfProject.plantsCurrentlyCOD),
        CODOfFirstPlantExpected: info?.stateOfProject.CODOfFirstPlantExpected
          ? dayjs(info?.stateOfProject.CODOfFirstPlantExpected)
          : null,
        CODOfLastPlantExpected: info?.stateOfProject.CODOfLastPlantExpected
          ? dayjs(info?.stateOfProject.CODOfLastPlantExpected)
          : null,
      });
      setStrategiesSale(
        info.strategiesSale.map((item) => ({
          strategyName: item?.transactionSummary?.strategyName
            ? item?.transactionSummary?.strategyName
            : null,
          strategyId: item?.transactionSummary?.strategyId
            ? item?.transactionSummary?.strategyId
            : null,
          developmentRisk: item.transactionSummary.developmentRisk,
          paymentMilestones: item?.transactionSummary?.paymentMilestones,
          minimumPaymentAtClosing:
            item.transactionSummary.minimumPaymentAtClosing,
          strategy: item.transactionSummary.strategy,
          acquisitionOfMajorityStake:
            item.transactionSummary.acquisitionOfMajorityStake,
          sellerToBeRetainedAsEPCContractor:
            item.transactionSummary.sellerToBeRetainedAsEPCContractor,
          targetPricePerMWpChecked:
            item.priceConsiderations.targetPricePerMWp > 0,
          targetPricePerMWpValue: item.priceConsiderations.targetPricePerMWp,
          pricePerMWpChecked:
            !item.priceConsiderations.targetPricePerMWp > 0 &&
            !item.priceConsiderations?.negotiablePrice,
          pricePerMWpValue: item.priceConsiderations.pricePerMWpFrom,
          pricePerMWpTo: item.priceConsiderations.pricePerMWpTo,
          negotiablePrice: item.priceConsiderations?.negotiablePrice,
          priceConsiderations: item.priceConsiderations.priceConsiderations,
          feeType: item.platformFee.alter5PlatformFeeType,
          feeConsiderations: item.platformFee.alter5PlatformFeeConsiderations,
          dateClosing: item?.otherConsiderations?.expectedClosingDate
            ? dayjs(item?.otherConsiderations?.expectedClosingDate)
            : null,
          includeTeaserProposal:
            item.otherConsiderations.includeProposedCapitalStructureInTeaser,
          hideToSponsor: item.otherConsiderations.hideTeaserToSponsor,
          hideAnnexed: item.otherConsiderations.hideAnnex1,
          other: item.otherConsiderations.otherConsiderations,
        }))
      );

      setStrategiesFinancing(
        info.strategiesFinancing.map((strategy) => ({
          ...strategy,
          additionalDocumentation: {
            ...strategy.additionalDocumentation,
            expectedClosingDate: strategy?.additionalDocumentation
              ?.expectedClosingDate
              ? dayjs(strategy.additionalDocumentation.expectedClosingDate)
              : null,
          },
          transactionSummary: {
            ...strategy.transactionSummary,
            financingType: Number(strategy.transactionSummary.financingType),
          },
        }))
      );

      setStrategiesTabs(
        projectType.order === 1
          ? info.strategiesFinancing.map((item, index) => {
              const position = index + 1;
              const tabName = item?.transactionSummary?.strategyName
                ? item.transactionSummary.strategyName
                : Number(item.transactionSummary.financingType) === 1
                ? "LOAN"
                : "ESI BOND";
              return {
                tab: tabName,
                key: `${position}`,
                strategyType: projectType.order,
                investmentInstrument: Number(
                  item.transactionSummary.financingType
                ),
                id: item?.transactionSummary?.strategyId,
              };
            })
          : info.strategiesSale.map((item, index) => {
              const position = index + 1;
              const tabName =
                item?.transactionSummary?.strategyName ||
                `New Strategy ${position}`;
              return {
                tab: tabName,
                key: `${position}`,
                strategyType: projectType.order,
                investmentInstrument: null,
                id: item?.transactionSummary?.strategyId
                  ? item?.transactionSummary?.strategyId
                  : null,
              };
            })
      );

      if (strategyId) {
        handleSelectStrategy(strategyId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDataTeaser, dataTeaser, projectType, lang, strategyId]);

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };
  const handleTabChange = (key) => {
    setTabActiveKey(key);
  };

  const handleSelectStrategy = (value) => {
    const newKey = (strategiesTabs.length + 1).toString();
    let selectedStrategy = strategiesList.find(
      (strategy) => strategy.value === value
    );
    if (!selectedStrategy) {
      return; 
    }

    let truncatedValue = value.slice(0, 20);
    if (value.length > 20) {
      truncatedValue += "...";
    }
    const newTabName = value === "" ? `New Strategy ${newKey}` : truncatedValue;
    const { type, investmentInstrument, id } = selectedStrategy;
    if (projectType?.name === "sale") {
      setStrategiesTabs((prevTabs) => [
        ...prevTabs,
        {
          tab: newTabName,
          key: newKey,
          strategyType: type,
          investmentInstrument: null,
          id: id,
        },
      ]);
      setStrategiesSale((prevSales) => [
        ...prevSales,
        { ...strategySaleStructure, strategyName: value, strategyId: id },
      ]);
    } else {
      setStrategiesTabs((prevTabs) => [
        ...prevTabs,
        {
          tab: newTabName,
          key: newKey,
          strategyType: type,
          investmentInstrument: investmentInstrument,
          id: id,
        },
      ]);

      setStrategiesFinancing((prevFinancing) => [
        ...prevFinancing,
        {
          ...strategyFinancingStructure,
          transactionSummary: {
            ...strategyFinancingStructure.transactionSummary,
            financingType: investmentInstrument,
            strategyName: value,
            strategyId: id,
            ...(investmentInstrument === 1 && {
              loan: {
                ...strategyFinancingStructure.transactionSummary.loan,
                targetAmount:
                  dataInitialTeaser?.data?.data?.financialModel?.total_Debt ||
                  null,
                tenor:
                  dataInitialTeaser?.data?.data?.financingSource?.legalTenor,
                margin:
                  dataInitialTeaser?.data?.data?.financingSource
                    ?.maximumLeverage,
                marginVariable:
                  dataInitialTeaser?.data?.data?.financingSource
                    ?.financingSpread,
                nonCall:
                  dataInitialTeaser?.data?.data?.financingSource?.nonCallPeriod,
                debtProfile:
                  dataInitialTeaser?.data?.data?.financingSource?.debtPayment,
              },
            }),
            ...(investmentInstrument === 2 && {
              esiBond: {
                ...strategyFinancingStructure.transactionSummary.esiBond,
                profile:
                  dataInitialTeaser?.data?.data?.financingSource?.debtPayment ||
                  null,
                maturity:
                  dataInitialTeaser?.data?.data?.financialModel
                    ?.commitment_fee_end_date || null,
              },
            }),
          },
          anexoOne: {
            ...strategyFinancingStructure.anexoOne,
            underlyingDebtTenor:
              dataInitialTeaser?.data?.data?.financingSource?.CSYearsInPeriod ||
              "",
            p90:
              dataInitialTeaser?.data?.data?.financingSource
                ?.productionScenario || null,
            averageDscr:
              dataInitialTeaser?.data?.data?.financingSource?.DSCRForMerchant ||
              "",
            priceCurve:
              dataInitialTeaser?.data?.data?.financingSource
                ?.priceCurveScenario || null,
          },
          anexoTwo: {
            ...strategyFinancingStructure.anexoTwo,
            epc: dataInitialTeaser?.data?.data?.financialModel?.EPC || null,
            otherCapex:
              dataInitialTeaser?.data?.data?.financialModel?.other_capex ||
              null,
            preOperationalCosts:
              dataInitialTeaser?.data?.data?.financialModel
                ?.pre_operational_costs || null,
            upfrontFee:
              dataInitialTeaser?.data?.data?.financialModel?.upfront_fee ||
              null,
            advisors:
              dataInitialTeaser?.data?.data?.financialModel?.advisors || null,
            agencyFee:
              dataInitialTeaser?.data?.data?.financialModel?.agency_fee || null,
            financialExpenses:
              dataInitialTeaser?.data?.data?.financialModel
                ?.financial_expenses || null,
            dsraEndowment:
              dataInitialTeaser?.data?.data?.financialModel?.DSRA_endowment ||
              null,
            equity:
              dataInitialTeaser?.data?.data?.financialModel?.total_sources ||
              null, //pending
            // pending seniorDebt: dataInitialTeaser?.data?.data?.financialModel?.total_sources  || null
          },
          anexoThree: {
            ...strategyFinancingStructure.anexoThree,
            debtToCapitalRatio:
              dataInitialTeaser?.data?.data?.financialModel
                ?.total_investment_Eur || null,
            debtToMwRatio:
              dataInitialTeaser?.data?.data?.financialModel
                ?.total_investment_Eur_MW || null,
            maxLoanLeverageOverCapexFrom:
              dataInitialTeaser?.data?.data?.financialModel?.total_spread ||
              null,
          },
        },
      ]);
    }
  };

  const handleDeleteStrategy = () => {
    setTabActiveKey("1");
    setStrategiesSale(
      strategiesSale.filter((_, index) => index !== Number(tabActiveKey - 1))
    );
    setStrategiesFinancing(
      strategiesFinancing.filter(
        (_, index) => index !== Number(tabActiveKey - 1)
      )
    );

    setStrategiesTabs((prevTabs) => {
      return prevTabs
        .filter((_, index) => index !== Number(tabActiveKey - 1))
        .map((item, index) => ({
          tab: item.tab,
          key: `${index + 1}`,
          strategyType: item.strategyType,
          investmentInstrument: Number(item.investmentInstrument),
          id: item.id,
        }));
    });
  };

  const handleChange = (event, form, setForm) => {
    const { value, id } = event.target;
    const newFormInfo = { ...form };
    newFormInfo[id] = value;
    setForm(newFormInfo);
  };
  const handleChangeNumber = (event, form, setForm) => {
    const { value, id } = event.target;
    const newFormInfo = { ...form };
    newFormInfo[id] = Number(value);
    setForm(newFormInfo);
  };
  const handleDate = (date, id, form, setForms) => {
    const newForm = { ...form };
    newForm[id] = date;
    setForms(newForm);
  };
  const handleSelect = (value, id, form, setForms) => {
    const newForm = { ...form };
    newForm[id] = value;
    setForms(newForm);
  };
  const handleRadioLang = (event) => {
    const { value, name } = event.target;
    const newForm = { ...projectSummary };
    newForm[name] = value;
    setProjectSummary(newForm);
  };

  const handleChangeStrategy = (event) => {
    const { value, id } = event.target;
    setStrategiesSale((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][id] = value;
      return updatedItems;
    });
  };
  const handleChangeNumberStrategy = (event) => {
    const { value, id } = event.target;
    setStrategiesSale((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][id] = Number(value);
      return updatedItems;
    });
  };
  const handleRadioStrategy = (event) => {
    const { value, name } = event.target;
    setStrategiesSale((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][name] = value;
      return updatedItems;
    });
  };
  const handleDateStrategy = (date, id) => {
    setStrategiesSale((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[Number(tabActiveKey) - 1][id] = date;
      return updatedItems;
    });
  };

  const items = [
    {
      key: "1",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            1.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("projectSummary")}
          </Text>
        </Row>
      ),
      panel: (
        <ProjectSummary
          projectSummary={projectSummary}
          setProjectSummary={setProjectSummary}
          handleChange={handleChange}
          handleSelect={handleSelect}
          handleChangeNumber={handleChangeNumber}
        />
      ),
    },
    {
      key: "2",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            2.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            Key Milestones
          </Text>
        </Row>
      ),
      panel: (
        <Milestones
          keyMilestones={keyMilestones}
          setKeyMilestones={setKeyMilestones}
        />
      ),
    },
    {
      key: "3",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            3.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("sponsorDescription")}
          </Text>
        </Row>
      ),
      panel: (
        <SponsorDescription
          sponsorDescription={sponsorDescription}
          setSponsorDescription={setSponsorDescription}
          handleChange={handleChange}
          handleChangeNumber={handleChangeNumber}
        />
      ),
    },
    {
      key: "4",
      header: (
        <Row gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            4.
          </Text>
          <Text
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h6}
          >
            {t("projectStatus")}
          </Text>
        </Row>
      ),
      panel: (
        <StateProject
          stateOfProject={stateOfProject}
          setStateOfProject={setStateOfProject}
          handleDate={handleDate}
          handleChangeNumber={handleChangeNumber}
        />
      ),
    },
  ];

  const handleSave = (onlySave, addStrategy, strategyIdAdded) => {
    toast.remove();
    const formData = {
      projectId: projectId,
      projectType: projectType?.name,
      projectSummary: {
        projectName: projectSummary.projectName,
        assetType: projectSummary.assetType,
        assetVertical: projectSummary.assetVertical,
        projectSize: String(projectSummary.projectSize),
        projectStatus: projectSummary.projectStatus,
        country: projectSummary.country,
        autonomousCommunity: projectSummary.community,
        currency: projectSummary.currency,
        declaredEstimatedConstruction: String(
          projectSummary.constructionDeclared
        ),
        numberOfPlants: String(projectSummary.numberFloors),
        projectRevenuesProfile: projectSummary.projectProfile,
        ppas: String(projectSummary.ppa),
        otherRelevantInformation: projectSummary.otherInformation,
        language: projectSummary.teaserLang,
      },
      keyMilestones: keyMilestones.filter(
        (item) => item.name !== "" && item.date !== null
      ),
      sponsorDescription: {
        description: sponsorDescription.description,
        yearsOfExperience: sponsorDescription.yearsExperience,
        completedProjects: sponsorDescription.completedProjects,
        totalMWDeveloped: sponsorDescription.totalMw,
        presenceIn: sponsorDescription.presenceCountries,
      },
      stateOfProject,
      strategiesSale: strategiesSale.map((strategy) => ({
        transactionSummary: {
          strategyName: strategy.strategyName,
          strategyId: strategy.strategyId,
          developmentRisk: String(strategy.developmentRisk),
          paymentMilestones: strategy.paymentMilestones,
          minimumPaymentAtClosing: String(strategy.minimumPaymentAtClosing),
          strategy: strategy.strategy,
          acquisitionOfMajorityStake: String(
            strategy.acquisitionOfMajorityStake
          ),
          sellerToBeRetainedAsEPCContractor: String(
            strategy.sellerToBeRetainedAsEPCContractor
          ),
        },
        priceConsiderations: {
          targetPricePerMWp: strategy.targetPricePerMWpValue,
          pricePerMWpFrom: strategy.pricePerMWpValue,
          pricePerMWpTo: strategy.pricePerMWpTo,
          priceConsiderations: strategy.priceConsiderations,
          negotiablePrice: strategy.negotiablePrice,
        },
        platformFee: {
          alter5PlatformFeeType: strategy.feeType,
          alter5PlatformFeeConsiderations: strategy.feeConsiderations,
        },
        otherConsiderations: {
          otherConsiderations: strategy.other,
          expectedClosingDate: strategy.dateClosing,
          includeProposedCapitalStructureInTeaser:
            strategy.includeTeaserProposal,
          hideTeaserToSponsor: strategy.hideToSponsor,
          hideAnnex1: strategy.hideAnnexed,
        },
      })),
    };
    const action = teaserId !== null ? editTeaser : saveTeaserSale;
    const dataToSend =
      teaserId !== null ? { teaserId: teaserId, formData: formData } : formData;
    action.reset();
    action.mutate(dataToSend, {
      onSuccess: (res) => {
        if (onlySave) {
          if (addStrategy && !teaserId) {
            navigate(
              `/projects/teaser-documentation?projectName=${projectName}&teaserId=${res?.data?.data?._id}&projectId=${projectId}&strategyId=${strategyIdAdded}`
            );
          } else {
            navigate(
              `/projects/teaser-documentation?projectName=${projectName}&teaserId=${res?.data?.data?._id}&projectId=${projectId}`
            );
            toast.success(t("success"));
            queryClient.invalidateQueries(["getStrategyByFinancingSource"]);
            queryClient.invalidateQueries(["getStrategies"]);
            queryClient.invalidateQueries(["getTeaserInfo"]);
          }
        } else {
          navigate("/projects");
        }
      },
      onError: (err) => {
        err.response.data.result.code.message.map((message) =>
          toast.error(message)
        );
      },
    });
  };

  const handleSaveFinancing = (onlySave, addStrategy, strategyIdAdded) => {
    toast.remove();
    const formData = {
      projectId: projectId,
      projectType: projectType?.name,
      projectSummary: {
        projectName: projectSummary.projectName,
        assetType: projectSummary.assetType,
        assetVertical: projectSummary.assetVertical,
        projectSize: String(projectSummary.projectSize),
        projectStatus: projectSummary.projectStatus,
        country: projectSummary.country,
        autonomousCommunity: projectSummary.community,
        currency: projectSummary.currency,
        declaredEstimatedConstruction: String(
          projectSummary.constructionDeclared
        ),
        numberOfPlants: String(projectSummary.numberFloors),
        projectRevenuesProfile: projectSummary.projectProfile,
        ppas: String(projectSummary.ppa),
        otherRelevantInformation: projectSummary.otherInformation,
        language: projectSummary.teaserLang,
      },
      keyMilestones: keyMilestones.filter(
        (item) => item.name !== "" && item.date !== null
      ),
      sponsorDescription: {
        description: sponsorDescription.description,
        yearsOfExperience: sponsorDescription.yearsExperience,
        completedProjects: sponsorDescription.completedProjects,
        totalMWDeveloped: sponsorDescription.totalMw,
        presenceIn: sponsorDescription.presenceCountries,
      },
      stateOfProject,
      strategiesFinancing: strategiesFinancing.map((strategy) => ({
        ...strategy,
        transactionSummary: {
          ...strategy.transactionSummary,
          financingType: `${strategy.transactionSummary.financingType}`,
        },
      })),
    };
    const action = teaserId !== null ? editTeaser : saveTeaserFinancing;
    const dataToSend =
      teaserId !== null ? { teaserId: teaserId, formData: formData } : formData;
    action.reset();
    action.mutate(dataToSend, {
      onSuccess: async (res) => {
        if (onlySave) {
          if (addStrategy && !teaserId) {
            navigate(
              `/projects/teaser-documentation?projectName=${projectName}&teaserId=${res?.data?.data?._id}&projectId=${projectId}&strategyId=${strategyIdAdded}`
            );
          } else {
            navigate(
              `/projects/teaser-documentation?projectName=${projectName}&teaserId=${res?.data?.data?._id}&projectId=${projectId}`
            );
            toast.success(t("success"));
            queryClient.invalidateQueries(["getStrategyByFinancingSource"]);
            queryClient.invalidateQueries(["getStrategies"]);
            queryClient.invalidateQueries(["getTeaserInfo"]);
          }
        } else {
          navigate("/projects");
        }
      },
      onError: (err) => {
        err.response.data.result.code.message.map((message) =>
          toast.error(message)
        );
      },
    });
  };

  const handleDownloadTeaser = () => {
    setLoadingDownload(true);
    downloadAndOpenFile(
      `teaser/${teaserId}/pdf?lang=${projectSummary.teaserLang}`,
      "Teaser",
      axiosClient,
      getToken()
    ).finally(() => {
      setLoadingDownload(false);
    });
  };

  const handleSelectStrategyContidion = (value) => {
    if (teaserId) {
      handleSelectStrategy(value);
    } else {
      if (projectType.order === 1) {
        handleSaveFinancing(true, true, value);
      } else {
        handleSave(true, true, value);
      }
    }
  };

  return (
    <ModuleContainer direction="column" padding="52px 30px 0 32px">
      <Card
        width="100%"
        height="auto"
        margin="0 0 39px 0"
        padding="10px 37px 0px 38px"
      >
        <Collapse
          activeKey={activeKey}
          handlePanelChange={handlePanelChange}
          panels={items}
          group={true}
          marginBottom={"0px"}
          headerHeight="50px"
        />
      </Card>
      <Card
        width="100%"
        height="auto"
        margin=" 0 0 20px 0"
        padding="10px 37px 0px 38px"
      >
        <Row justify="space-between" align="center">
          <Text
            size={theme.fonts.size.h5}
            color={theme.colors.blue}
            weight={theme.fonts.weight.medium}
          >
            {t("strategies")}
          </Text>
          <Select
            placeholder={t("addStrategy")}
            width="310px"
            options={strategiesList}
            onChange={handleSelectStrategyContidion}
            value={null}
          />
        </Row>
        <Row>
          <Tabs onChange={handleTabChange} activeKey={tabActiveKey}>
            {strategiesTabs.map((panel) => (
              <Tabs.Panel tab={panel.tab} key={panel.key} />
            ))}
          </Tabs>
        </Row>

        {strategiesTabs.length > 0 && projectType?.name === "sale" && (
          <Strategy
            tabActiveKey={tabActiveKey}
            strategyData={strategiesSale}
            setStrategyData={setStrategiesSale}
            handleChangeNumberStrategy={handleChangeNumberStrategy}
            handleChangeStrategy={handleChangeStrategy}
            handleRadioStrategy={handleRadioStrategy}
            handleDateStrategy={handleDateStrategy}
            handleDeleteStrategy={handleDeleteStrategy}
          />
        )}
        {strategiesTabs.length > 0 && projectType?.name === "financing" && (
          <FinancingStrategy
            tabActiveKey={tabActiveKey}
            strategyData={strategiesFinancing}
            setStrategyData={setStrategiesFinancing}
            handleDeleteStrategy={handleDeleteStrategy}
            strategiesTabs={strategiesTabs}
            p50Condition={
              dataInitialTeaser?.data?.data?.financingSource?.productionScenario
            }
          />
        )}

        {teaserId && (
          <Row align="center" m="0 0 47px 0" gap="14px">
            <Col>
              <Text color={theme.colors.gray200} align="left" mb="10px">
                {"Idioma de descarga"}
              </Text>
              <Row gap="24px">
                <Radio
                  name="teaserLang"
                  label="Español"
                  value={"es"}
                  checked={projectSummary?.teaserLang === "es"}
                  onChange={handleRadioLang}
                />
                <Radio
                  name="teaserLang"
                  label="Ingles"
                  value={"en"}
                  checked={projectSummary?.teaserLang === "en"}
                  onChange={handleRadioLang}
                />
              </Row>
            </Col>
            <Button
              width="196px"
              height="45px"
              onClick={handleDownloadTeaser}
              disabled={!teaserId}
              loading={loadingDownload}
            >
              <Row justify="center" align="center" gap="8px">
                <EyeIcon fill={theme.colors.white} width="20px" height="20px" />
                {t("previewTeaser")}
              </Row>
            </Button>
          </Row>
        )}
      </Card>

      <Row justify="center" align="center" m="0 0 47px 0" gap="14px">
        <Button
          width="196px"
          height="45px"
          onClick={
            projectType.order === 1
              ? () => handleSaveFinancing(true, false, null)
              : () => handleSave(true, false, null)
          }
          loading={
            saveTeaserSale.isLoading ||
            editTeaser.isLoading ||
            saveTeaserFinancing.isLoading
          }
        >
          {t("save")}
        </Button>
        <Button
          width="196px"
          height="45px"
          onClick={
            projectType.order === 1
              ? () => handleSaveFinancing(true, false, null)
              : () => handleSave(true, false, null)
          }
          loading={
            saveTeaserSale.isLoading ||
            editTeaser.isLoading ||
            saveTeaserFinancing.isLoading
          }
        >
          {t("saveExit")}
        </Button>
      </Row>

      <Text
        size={theme.fonts.size.sm}
        color={theme.colors.gray300}
        align="center"
      >
        {t("powered")}
      </Text>
    </ModuleContainer>
  );
};
