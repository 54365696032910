import React, { useState } from "react";
import { Div, Text, Row, ModuleContainer } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Table } from "../../components/Table";
import { Skeleton } from "../../components/Skeleton";
import { Card } from "../../components/Card";
import Checkbox from "../../components/Checkbox";
import { CloseIcon, SendIcon } from "../../assets/icons";
import { ModalPromoterQuestions } from "./ModalPromoterQuestions";
//import { useNavigate } from "react-router-dom";
export const ManagerQuestions = () => {
  const { t } = useTranslation("managerQuestions");
  //const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const data = [
    {
      nameCompany: (
        <Row>
          {" "}
          <Checkbox label={"Santander"} colorLabel={theme.colors.gray500} />
        </Row>
      ),
      nameInvestor: "Jhon Smith",
      theme: "Medio Ambiente",
      question: "Hay pajaros en la zona?",
      state: "Pendiente",
    },
    {
      nameCompany: (
        <Row>
          {" "}
          <Checkbox label={"Alter 5"} colorLabel={theme.colors.gray500} />
        </Row>
      ),
      nameInvestor: "Jhon Smith",
      theme: "Intercone…",
      question: "Enviar diseño de conexión y estimación del coste",
      state: "Pendiente",
    },
    {
      nameCompany: (
        <Row>
          {" "}
          <Checkbox label={"Santander"} colorLabel={theme.colors.gray500} />
        </Row>
      ),
      nameInvestor: "Jhon Smith",
      theme: "Medio Ambiente",
      question: "Hay pajaros en la zona?",
      state: "Derivada",
    },
    {
      nameCompany: (
        <Row>
          {" "}
          <Checkbox label={"Alter 5"} colorLabel={theme.colors.gray500} />
        </Row>
      ),
      nameInvestor: "Jhon Smith",
      theme: "Intercone…",
      question: "Enviar diseño de conexión y estimación del coste",
      state: "Respondida",
    },
  ];

  const tableColumns = [
    {
      title: t("nameCompany"),
      dataIndex: "nameCompany",
      key: "nameCompany",
    },
    {
      title: t("nameInvestor"),
      dataIndex: "nameInvestor",
      key: "nameInvestor",
    },
    {
      title: t("theme"),
      dataIndex: "theme",
      key: "theme",
    },
    {
      title: t("question"),
      dataIndex: "question",
      key: "question",
    },
    {
      title: t("status"),
      dataIndex: "state",
      key: "state",
      render: (value) => (
        <Div
          width="auto"
          height="36px"
          radius="20px"
          p="10px"
          background={stateColors(value).backgroundColor}
          style={{ color: stateColors(value).textColor }}
          align="center"
          justify="center"
        >
          {value}
        </Div>
      ),
    },
    {
      title: t("actions"),
      dataIndex: "actions",
      key: "actions",
      render: () => (
        <Div justify="space-between" style={{ cursor: "pointer" }}>
          <SendIcon
            fill={theme.colors.green}
            onClick={() => setShowModal(true)}
          />
          <CloseIcon fill={theme.colors.green} />
        </Div>
      ),
    },
  ];

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const stateColors = (state) => {
    switch (state) {
      case "Pendiente":
        return {
          textColor: theme.colors.yellow700,
          backgroundColor: theme.colors.yellow100,
        };
      case "Derivada":
        return {
          textColor: theme.colors.blue400,
          backgroundColor: theme.colors.blue200,
        };
      case "Respondida":
        return {
          textColor: theme.colors.green500,
          backgroundColor: theme.colors.greenLight,
        };

      default:
        break;
    }
  };

  return (
    <ModuleContainer padding="35px 50px 40px 50px">
      <ModalPromoterQuestions
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
      <Card
        padding={"22px 29.5px 30px 29px"}
        height={"534px"}
        width={"100%"}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Div width="100%" align="center" justify="space-between" m="0 0 51px 0">
          <Text
            color={theme.colors.blue}
            size={theme.fonts.size.h5}
            weight={theme.fonts.weight.medium}
          >
            {t("manageQuestions")}
          </Text>
          <Div align="center" gap="16px">
            <Checkbox
              label={t("frequentlyAskedQuestion")}
              colorLabel={theme.colors.green}
            />
            <Button width={"187px"}>{t("askQuestions")}</Button>
          </Div>
        </Div>
        <Div style={{ flex: 1, overflow: "auto" }}>
          <Table
            data={data}
            shadow={"0px"}
            padding={"0px"}
            columns={tableColumns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            pagination={false}
            locale={{
              emptyText: (
                <Div justify="center" gap="16px">
                  {[...Array(9)].map((item, idx) => (
                    <Skeleton
                      key={idx}
                      title={false}
                      paragraph={{
                        rows: 10,
                        width: "100%",
                      }}
                    />
                  ))}
                </Div>
              ),
            }}
          />{" "}
        </Div>
      </Card>
    </ModuleContainer>
  );
};

ManagerQuestions.propTypes = {};
