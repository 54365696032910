import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../components/Modal";
import { theme } from "../../styles/theme";
import { Col, Row, Text } from "../../styles/Common";
import { FolderIcon } from "../../assets/icons";
import { Button } from "../../components/Button";

export const ModalPromoterQuestions = ({ showModal, handleCloseModal }) => {
  const { t } = useTranslation("managerQuestions");

  return (
    <Modal open={showModal} onCancel={handleCloseModal}>
      <Modal.Header
        iconBackground={theme.colors.green100}
        iconWidth={"50px"}
        iconHeight={"50px"}
        icon={
          <FolderIcon width="50px" height="50px" fill={theme.colors.green} />
        }
        align="-webkit-center"
        padding={"20px 50px 0px 50px"}
        title={t("ModalPromoterQuestions.title")}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="28px 0 32px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%">
          <Row width="100%" m="0 0 36px 0" justify="center" align="center">
            <Text size={theme.fonts.size.h6} color={theme.colors.gray500}>
              {t("ModalPromoterQuestions.description")}
            </Text>
          </Row>
          <Row justify="center">
            <Button width={"229px"}>
              {t("ModalPromoterQuestions.seeQuestions")}
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

ModalPromoterQuestions.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};
