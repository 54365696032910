import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { Div, Text } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { theme } from "../../../../styles/theme";
import search from "../../../../assets/icons/Search.svg";
//import filterIcon from "../../../../assets/icons/Filtrar.svg";
import { useTranslation } from "react-i18next";
//import { Button } from "../../../../components/Button";
import eolicImg from "../../../../assets/images/eolicImage.png";
import solarImg from "../../../../assets/images/solar.png";
import useGetProjects from "../../hooks/useGetProjects";
import { Link } from "../../../../components/Link";
import {
  InitInvestorFilters,
  getUserInfo,
  fetchImageUrl,
  getToken,
} from "../../../../utilities/helpers";
import { FilterDrawer } from "./filterDrawer";
import { FinancingProjects } from "./financingProjects";
import { SaleProjects } from "./saleProjects";
import useSetActivityLog from "../../../InvestorProjectDetail/hooks/useSetActivityLog";
import { Col, Row } from "antd";
import { CloseIcon } from "../../../../assets/icons";

export const InvestorProjectList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("projectsTabsView");
  const { companyId } = getUserInfo();
  const { projectType } = useSelector((state) => state?.common);
  const setLog = useSetActivityLog();
  const [searchValue, setSearchValue] = useState("");
  const [openFilters, setOpenFilters] = useState(false);
  const [totalCreated, setTotalCreated] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [fetchAction, setFetchAction] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [imageUrl, setImageUrl] = useState(null);
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    const loadImage = async () => {
      if (companyId) {
        try {
          const url = `documentation/download-by-company?category=9&companyId=${companyId}`;
          const image = await fetchImageUrl(url, getToken());
          setImageUrl(image);
        } catch (error) {
          toast.error(t("errorImage"), error);
        }
      }
    };

    loadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const [payload, setPayload] = useState({
    instance: [],
    limit: pageSize,
    name: "",
    offset: 0,
    projectType: [],
    selectedTab: "created",
    groupBy: "invitations",
  });

  const getProjects = useGetProjects(payload);
  const [projectsData, setProjectsData] = useState([]);
  const { data: dataProjects, isLoading, isSuccess } = getProjects;

  useEffect(() => {
    if (isSuccess&& dataProjects?.data) {
      setProjectsData(dataProjects?.data?.data);
      setTotalCreated(dataProjects?.data?.totalCreated);
      setInitialLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjects, isSuccess]);

  const [filtersOptions, setFilterOptions] = useState({
    "filters.type": [
      { label: "filters.solar", value: "solar", checked: false },
      { label: "filters.eolic", value: "eolic", checked: false },
    ],
    "filters.capacity": [
      { label: "- 10MW", value: "-10", checked: false },
      { label: "10MW - 50MW", value: "10-50", checked: false },
      { label: "+ 50MW", value: "+50", checked: false },
    ],
    "filters.countries": [
      { label: "España", value: "es", checked: false },
      { label: "Reino Unido", value: "uk", checked: false },
      { label: "Italia", value: "it", checked: false },
      { label: "USA", value: "us", checked: false },
    ],
    "filters.phase": [
      { label: "Pre-development", value: 1, checked: false },
      { label: "Development", value: 2, checked: false },
      { label: "RtB or Rtf Imminent", value: 3, checked: false },
      { label: "Construction", value: 4, checked: false },
      { label: "Operation", value: 5, checked: false },
    ],
    "filters.strategy": [
      { label: "Minority Equity Shareholding", value: 1, checked: false },
      { label: "Co-develpment", value: 2, checked: false },
      { label: "Assest Acquisition", value: 3, checked: false },
      { label: "Construction Bridge Debt - DI", value: 4, checked: false },
      { label: "Development Bridge Debt - DI", value: 5, checked: false },
    ],
  });

  useEffect(() => {
    setPayload({
      ...payload,
      limit: pageSize,
      name: searchValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, fetchAction]);

  useEffect(() => {
    const instance = projectType === "debt" ? "financing" : "sale";
    setPayload({
      ...payload,
      instance: [instance],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectType]);

  const returnImg = (type) => {
    switch (type) {
      case "solar":
        return solarImg;
      case "eolic":
        return eolicImg;
      default:
        break;
    }
  };

  const handleFilterChange = (event, category, indx) => {
    const { id, checked } = event.target;
    if (category === "filters.type") {
      const updatedOptions = { ...filtersOptions };
      updatedOptions["filters.type"][indx].checked = checked;
      setFilterOptions({ ...updatedOptions });
      setPayload({ ...payload, projectType: [...payload.projectType, id] });
    }
  };

  const handleReFetch = () => {
    setPageSize(5);
    setFetchAction(!fetchAction);
  };

  const handleSaveFilters = () => {
    handleReFetch();
    setOpenFilters(false);
  };

  const cleanFilters = () => {
    setFilterOptions(InitInvestorFilters);
    handleReFetch();
    setOpenFilters(false);
  };

  const handleSearch = () => {
    handleReFetch();
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
      setIsSearch(!isSearch);
    }
  };

  const handleDetail = (projectId) => {
    const payload = {
      action: "visit-opportunity",
      projectId: projectId,
    };

    navigate(`/projects/investor-project-detail?projectId=${projectId}`);
    setLog.reset();
    setLog.mutate(payload);
  };

  /*   const openDrawer = () => {
    setOpenFilters(true);
  }; */

  const handleLoadProjects = () => {
    setPageSize(pageSize + 5);
  };

  if (initialLoading) {
    return (
      <Div width="100%" height="100%" justify="center" align="center">
        <ReactLoading color={theme.colors.green} />
      </Div>
    );
  }

  return (
    <>
      <Div height="min-content" justify="space-between" align="center">
        <FilterDrawer
          open={openFilters}
          onClose={() => setOpenFilters(false)}
          filtersOptions={filtersOptions}
          handleFilterChange={handleFilterChange}
          handleSaveFilters={handleSaveFilters}
          cleanFilters={cleanFilters}
        />
        <Div direction="column" gap="16px">
          <img
            alt="icon"
            src={imageUrl}
            width="264px"
            height={"70px"}
            style={{ objectFit: "contain", objectPosition: "left" }}
          />
          <Text size={theme.fonts.size.h6} color={theme.colors.gray300}>
            {t("projectsSubtitle")}
          </Text>
        </Div>
        <Div gap="24px">
          <Input
            sizeLabel={theme.fonts.size.xs}
            suffix={
              <img
                alt="search"
                src={search}
                style={{ cursor: "pointer" }}
                onClick={handleSearch}
              />
            }
            width={"323px"}
            placeholder={`${t("common:search")}...`}
            style={{ borderRadius: "23px", marginTop: "-10px" }}
            mt={"-8px"}
            value={searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          {/* <Button width={"110px"} variant={"outlined"} onClick={openDrawer}>
            {t("filter")}
            <img alt="icon" src={filterIcon} style={{ marginLeft: "7px" }} />
          </Button> */}
        </Div>
      </Div>
      <Div direction="column" m="42px 0 52px 0" gap="26px">
        <Text
          color={theme.colors.blue}
          size={theme.fonts.size.h5}
          weight={theme.fonts.weight.medium}
        >
          {t("allProjects")}
        </Text>
        <Text color={theme.colors.gray300}>{t("projectsMessage")}</Text>
      </Div>
      {isSearch && projectsData.length === 0 && (
        <Col width="100%" justify="center" align="center" m="24px 0">
          <Text color={theme.colors.gray500} size={theme.fonts.size.h6}>
            {t("projectsTabsView:nosearch")}
          </Text>
          <Row justify="center" align="center">
            <Text
              color={theme.colors.green}
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.medium}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSearchValue("");
                handleReFetch();
                setIsSearch(false);
              }}
            >
              {t("projectsTabsView:cleanSearch")}
            </Text>
            <CloseIcon fill={theme.colors.green} />
          </Row>
        </Col>
      )}
      {isLoading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.green} />
        </Div>
      ) : (
        <>
          {projectType === "debt" ? (
            <FinancingProjects
              projectsData={projectsData}
              returnImg={returnImg}
              handleDetail={handleDetail}
            />
          ) : (
            <SaleProjects
              projectsData={projectsData}
              returnImg={returnImg}
              handleDetail={handleDetail}
            />
          )}
          {payload.limit < totalCreated && (
            <Div
              width="100%"
              m="40px 0 0 0"
              justify="center"
              onClick={handleLoadProjects}
            >
              <Link>{t("loadProjects")}</Link>
            </Div>
          )}
        </>
      )}
    </>
  );
};
